<template>
  <div id="Messages" class="chat-view tw-pt-8 tw-z-0 tw-overflow-auto tw-fixed tw-row custom-scroll">
    <div v-if="filterContacts.length > 0">
      <MessageComponent />
    </div>
    <div v-else-if="filterContacts.length == 0 && !findChat">
      <div class="tw-mt-20 tw-w-full tw-text-center font-style">
        Nenhum contato encontrado.
      </div>
    </div>
    <div v-if="findChat" id="Message">
    </div>
  </div>
</template>

<script setup>
import MessageComponent from '../components/MessageComponent.vue';
</script>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('chat');
export default {
  methods: {
    ...mapActions(['defineFilterContacts']),
  },
  computed: {
    ...mapState(['filterContacts', 'findChat']),
  },
  mounted() {
    this.defineFilterContacts();
  }
}
</script>

<style>
.chat-view {
  width: 420px;
  height: calc(100vh - 100px);
}

.font-style {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 18px;
  color: rgb(105, 105, 105);
  font-weight: 400;
}
</style>
