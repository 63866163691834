<template>
  <v-dialog
    width="60%"
    persistent
    v-model="show"
    @click:outside="closeDialog"
    scrollable
  >
    <div class="tw-h-full tw-w-full tw-bg-white">
      <div class="">
        <div
          class="tw-w-full tw-flex tw-gap-4 tw-text-white tw-p-4 tw-items-center tw-bg-green-700"
        >
          <v-btn
            @click="closeDialog"
            elevation="0"
            class="close-btn tw-bg-green-700"
          >
            <CloseIcon fill-color="#f0f0f0" />
          </v-btn>
          <div class="tw-text-2xl">Iniciar novo Chat</div>
        </div>
        <div class="tw-w-full tw-bg-white tw-h-full tw-p-4">
          <div
            class="input-search tw-flex tw-items-center tw-bg-white tw-rounded-full"
          >
            <MagnifyIcon
              fillColor="#179788"
              :size="18"
              class="tw-ml-2 tw-w-2"
            />
            <input
              class="tw-mx-5 tw-my-1 tw-w-full tw-py-0.5 tw-text-gray-700 tw-bg-white"
              autocomplete="off"
              id="search"
              type="text"
              v-model="findClient"
              placeholder="Pesquisar cliente"
            />
          </div>
        </div>
        <div class="text-green tw-px-4 tw-mt-7">
          Clientes
        </div>
      </div>
      <div class="tw-px-6 tw-py-4 listClients custom-scroll">
        <ul
          v-for="(client, index) in filterClients(clients)"
          class="tw-border-t-2 tw-p-2 clientHover overflow-y-auto"
          v-if="client.hasWhats"
        >
          <li>
            <div class="">
              {{ client.name }}
            </div>
            <div class="tw-font-light tw-text-sm tw-text-gray-400">
              {{ client.contact }}
            </div>
          </li>
        </ul>
        <div
          v-if="!filterClients(clients).length"
          class="tw-text-center tw-mt-8 tw-text-gray-400 tw-text-lg tw-font-light"
        >
          Nenhum cliente encontrado
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState: mapStateClients } = createNamespacedHelpers('clients');

export default {
  props: { value: Boolean, attendence: Object },
  data() {
    return {
      findClient: '',
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close', false);
    },
    filterClients(clients) {
      let array = [];
      array = clients.filter((el) =>
        el.name.toLowerCase().includes(this.findClient.toLowerCase())
      );
      if (!array.length) {
        array = clients.filter((el) => el.contact.includes(this.findClient));
      }
      return array;
    },
  },
  computed: {
    ...mapStateClients(['clients']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<script setup>
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<style>
.v-dialog{
  overflow: hidden !important;
}
#search {
  outline: none;
}
.clientHover:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
  border-radius: 6px;
}
.close-btn {
  min-width: 36px !important;
  width: 36px;
  border-radius: 36px;
  height: 36px;
}
.input-search{
  border: 1px solid #179788;
}
.text-green{
  color: #48beb0;
  font-size: 18px;
}
</style>
<style scoped>
.listClients {
  height: calc(615px - 214px);
  overflow-y: auto;
}
</style>
