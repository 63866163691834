import { render, staticRenderFns } from "./startNewChatDialog.vue?vue&type=template&id=47663b96&scoped=true&"
import script from "./startNewChatDialog.vue?vue&type=script&setup=true&lang=js&"
export * from "./startNewChatDialog.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./startNewChatDialog.vue?vue&type=style&index=0&id=47663b96&prod&lang=css&"
import style1 from "./startNewChatDialog.vue?vue&type=style&index=1&id=47663b96&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47663b96",
  null
  
)

export default component.exports