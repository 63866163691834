<template>
  <div class="tw-flex tw--mt-15">
    <div id="home" class="tw-fixed tw-z-10">
      <div class="bg-header tw-w-full tw-flex tw-items-center tw-px-3 tw-py-2 tw-border-r tw-border-gray-300"
        style="background-color: #f0f0f0">
        <UserPhoto size="40" width="40" height="40" :user="loggedUser" class="tw-rounded-full tw-ml-1" />
        <div class="tw-flex tw-items-center tw-justify-center">
          <div id="search" class="tw-flex">
            <div class="search-input tw-px-1 tw-m-2 tw-flex tw-items-center tw-bg-white tw-rounded-full">
              <MagnifyIcon fillColor="#179788" :size="18" class="tw-ml-2 tw-w-2" />
              <input class="tw-mx-5 tw-mr-4 tw-my-1 tw-w-full tw-py-1.5 tw-text-gray-700 tw-leading-tight tw-bg-white"
                id="newChat" autocomplete="off" type="text" placeholder="Pesquisar contato" v-model="findChat" />
            </div>
          </div>
        </div>
      </div>

      <div id="menu"
        class="tw-bg-white tw-flex tw-items-center tw--ml-1 tw-w-full tw-px-6 tw-py-4 tw-border-b tw-shadow-sm tw-gap-4">
        <v-menu v-model="isMenuActive" transition="slide-y-transition">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn elevation="0" color="#fff" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon size="28px" color="#179788">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <span>Outros Filtros</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item class="hover-menu">
              <v-menu open-on-hover right :offset-x="true">
                <template v-slot:activator="{ on, attrs }">
                  <div class="tw-w-full tw-py-4" v-bind="attrs" v-on="on">
                    Tags 
                  </div>
                </template>
                <v-list>
                  <v-list-item @click="changeTagFilter(tag)" class="hover-menu" v-for="tag in tags">
                    <v-list-item-title>{{ tag.name }}</v-list-item-title>
                    <label-icon :iconColor="tag.color" iconSize="24px" class="tw-ml-2" />
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
            <v-list-item class="hover-menu">
              <v-menu open-on-hover right :offset-x="true">
                <template v-slot:activator="{ on, attrs }">
                  <div class="tw-w-full tw-py-4" v-bind="attrs" v-on="on">
                    Departamentos
                  </div>
                </template>
                <v-list>
                  <v-list-item @click="changeDepartmentFilter(department)" class="hover-menu" v-for="department in filterDepartments()">
                    <v-list-item-title>{{ department.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="changeFilter('Todas as Seções')" class="tw-w-1/5" v-bind="attrs" v-on="on" elevation="0px"
              color="#ffffff" width="30px">
              <all-sections-icon />
            </v-btn>
          </template>
          <span>Todas as Seções</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="changeFilter('Aguardando Atendimento')" class="tw-w-1/5" v-bind="attrs" v-on="on"
              elevation="0px" color="#ffffff" width="30px">
              <waiting-attendences-icon />
            </v-btn>
          </template>
          <span>Aguardando Atendimento</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="changeFilter('Em Atendimento')" class="tw-w-1/5" v-bind="attrs" v-on="on" elevation="0px"
              color="#ffffff" width="30px">
              <chatting-icon />
            </v-btn>
          </template>
          <span>Em Atendimento</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="changeFilter('Finalizados')" class="tw-w-1/4" v-bind="attrs" v-on="on" elevation="0px"
              color="#ffffff" width="30px">
              <finished-section-icon iconColor="#179788" iconSize="28px" />
            </v-btn>
          </template>
          <span>Finalizados</span>
        </v-tooltip>
      </div>
    </div>

    <div>
      <ChatsView class="tw-mt-24" />
    </div>
    <div>
      <MessageView />
    </div>
    <startNewChatDialog v-if="modelDialogStartChat" @close="closeDialog" v-model="modelDialogStartChat">
    </startNewChatDialog>
  </div>
</template>

<script>
import ChatsView from './ChatsView.vue';
import MessageView from './MessageView.vue';

import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical.vue';
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import startNewChatDialog from './../modals/startNewChatDialog.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapState: mapStateConfig } = createNamespacedHelpers('config');
const { mapState, mapActions } = createNamespacedHelpers('chat');

export default {
  components: {
    ChatsView,
    MessageView,
    DotsVerticalIcon,
    MagnifyIcon,
  },
  data() {
    return {
      isMenuActive: false,
      modelDialogStartChat: null,
      findChat: '',
    };
  },
  methods: {
    ...mapActions(['filterChats', 'applyFilter', 'applyFilterTag', 'applyFilterDepartment']),
    filterDepartments() {
      return this.loggedUser.departments;
    },
    openChatDialog() {
      this.modelDialogStartChat = true;
    },
    closeDialog() {
      this.modelDialogStartChat = false;
    },
    changeFilter(filter) {
      this.applyFilter(filter);
    },
    changeTagFilter(tag){
      this.applyFilterTag(tag);
      this.isMenuActive = false;
    },
    changeDepartmentFilter(department){
      this.applyFilterDepartment(department);
      this.isMenuActive = false;
    }
  },
  watch: {
    findChat: {
      handler(val, oldValue) {
        this.filterChats(val.toLowerCase());
      },
    },
  },
  computed: {
    ...mapStateConfig(['loggedUser', 'tags', 'departments']),
    ...mapState(['contacts']),
  },
};
</script>

<style>
#newChat:focus {
  outline: none;
}

#home {
  width: 420px;
}

#newChat::placeholder {
  font-size: small;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

#menu {
  width: 424px !important;
  border: 1px solid #d1d5db;
  border-bottom: 0px !important;
  border-top: 0px !important;
}

.main-painel {
  background: none !important;
}

.fill-height {
  margin-top: -10px;
}

.search-input {
  width: 320px;
  border: 1px solid #179788;
}

.bg-header {
  background-color: #f0f0f0;
}
.hover-menu:hover{
  background-color: #f0f0f0;
  transform: scale(1.03);
}
</style>
