<template>
  <div class="tw-py-3">
    <div v-if="filterContacts">
      <div class="tw-flex tw-w-full tw-px-2 tw-py-2 tw-items-center tw-cursor-pointer tw-border oneContact"
        v-for="(contact, index) in filteredContacts" @click="contactSelect(contact)">
        <div v-if="contact.profilePic">
          <img class="tw-rounded-full tw-mr-4 tw-w-12" :src="contact.profilePic" />
        </div>
        <div v-else-if="!contact.profilePic && contact.isGroup" class="tw-rounded-full tw-p-2 tw-bg-gray-400 tw-mr-2">
          <group-icon iconSize="32" iconColor="#d3d3d3" />
        </div>
        <div v-else-if="!contact.profilePic && !contact.isGroup" class="tw-rounded-full tw-p-2 tw-bg-gray-400 tw-mr-2">
          <contact-icon iconSize="32" iconColor="#000000" />
        </div>
        <div class="tw-w-full">
          <div class="tw-flex">
            <div class="tw-flex tw-items-center">
              <div class="name-notify" v-if="contact.name && contact.unreadCount">{{ contact.name }}</div>
              <div class="name-div" v-else-if="contact.name">{{ contact.name }}</div>
              <div class="name-notify" v-else-if="contact.unreadCount">{{ contact.pushname }}</div>
              <div class="name-div" v-else-if="contact.pushname">{{ contact.pushname }}</div>
              <div class="name-notify" v-else-if="contact.unreadCount">{{ formattedNumber(contact._serialized) }}</div>
              <div class="name-div" v-else>{{ formattedNumber(contact._serialized) }}</div>
              <div class="tw-flex tw-items-center">
                <div v-for="tag in tags">
                  <div class="tw-ml-1" v-if="tag.id == contact.tagId">
                    <label-icon iconSize="18" :iconColor="tag.color" />
                  </div>
                </div>
                <div v-if="contact.isNewChat" class="newChatNotification-class">
                  novo
                </div>
                <div v-else-if="contact.isPriority" class="departmentIcon-class tw-mx-1 tw-rounded-full tw-bg-gray-200">
                  <departament-icon iconColor="#25d366" iconSize="18px" />
                </div>
              </div>
            </div>
            <div class="tw-ml-auto tw-flex tw-items-center tw-justify-center">
              <div v-if="contact.isPriority"
                class="textIsPriority-class tw-font-light tw-flex tw-flex-col tw-items-center tw-justify-center">
                <span class="tw-font-bold">departamento:</span> <br> <span class="text-atendimento tw--mt-4">{{
                  findCurrentDepartment(contact.currentDepartmentId).name }}</span>
              </div>
              <div v-if="contact.timestamp && contact.unreadCount" class=" notify-hour tw-text-xs tw-font-light">
                {{ defineHour(contact.timestamp) }}
              </div>
              <div v-else-if="contact.timestamp && contact.isPriority" class="tw-text-xs tw-font-light">
                {{ defineHour(contact.timestamp) }}
              </div>
              <div v-else-if="contact.timestamp" class="tw-ml-auto tw-text-xs tw-font-light">
                {{ defineHour(contact.timestamp) }}
              </div>
            </div>
          </div>

          <div class="tw-flex tw-items-center">
            <div v-if="contactMessages(contact.id)?.lastMessageType == 'chat' && contact.unreadCount"
              class="hide-text lastMessage-notify">
              {{ contactMessages(contact.id)?.lastMessageBody }}
            </div>
            <div v-else-if="contactMessages(contact.id).lastMessageType == 'chat'" class="hide-text ">
              {{ contactMessages(contact.id)?.lastMessageBody }}
            </div>
            <div v-else-if="contactMessages(contact.id)?.lastMessageType == 'ptt'"
              class="tw-flex tw-justify-start tw-items-center tw-gap-1">
              <mic-icon iconSize="18px" iconColor="#8696a0" />
              <span class="fontComponent">Áudio</span>
            </div>
            <div v-else-if="contactMessages(contact.id)?.lastMessageType == 'sticker'"
              class="tw-flex tw-justify-start tw-items-center tw-gap-1">
              <sticker-icon iconSize="18px" iconColor="#8696a0" />
              <span class="fontComponent">Figurinha</span>
            </div>
            <div v-else-if="contactMessages(contact.id)?.lastMessageType == 'image'"
              class="tw-flex tw-justify-start tw-items-center tw-gap-1">
              <image-icon iconSize="18px" iconColor="#8696a0" />
              <span class="fontComponent">Imagem</span>
            </div>
            <div v-else-if="contactMessages(contact.id)?.lastMessageType == 'document'"
              class="tw-flex tw-justify-start tw-items-center tw-gap-1">
              <document-icon iconSize="18px" iconColor="#8696a0" />
              <span class="fontComponent">Documento</span>
            </div>

            <div v-if="contact.unreadCount" class="notify-color tw-text-center tw-ml-auto tw-rounded-full tw-text-sm">
              <div class="notify-text">
                {{ contact.unreadCount }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tw-mt-36">
      <div class="tw-font-light tw-text-gray-400">Nenhum contato encontrado. AAA</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('chat');
const { mapState: mapStateConfig, mapActions: mapActionsConfig } = createNamespacedHelpers('config');
import moment from 'moment';
export default {
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    ...mapActions(['selectContact', 'resetUnreadCount']),
    contactMessages(id) {
      let array = this.messages.filter((item) => item.chatId == id);
      if (array.length > 0) {
        const max = array.reduce((a, b) => {
          if (b.timestamp > a.timestamp) a = b;
          return a;
        });
        const objectMessage = {
          lastMessageBody: max.body,
          lastMessageTime: max.timestamp,
          lastMessageType: max.type,
        }
        return objectMessage;
      } else {
        return '';
      }
    },
    findCurrentDepartment(id) {
      return this.departments.find((el) => el.id == id);
    },
    formattedNumber(seri) {
      let arrString = seri.split('');
      arrString.unshift("+");
      arrString.splice(3, 0, " ");
      arrString.splice(4, 0, "(");
      arrString.splice(7, 0, ")");
      arrString.splice(8, 0, " ");
      arrString.splice(13, 0, "-");
      arrString.splice(18, arrString.length);
      arrString = arrString.join("");
      return arrString;
    },
    defineHour(timestamp) {
      var today = moment().format('DD/MM/YYYY');
      var data = moment(timestamp * 1000).format('DD/MM/YYYY');
      if (today == data) {
        var dateFormat = moment(timestamp * 1000).format('HH:mm');
        return dateFormat;
      } else {
        return this.defineDate(timestamp);
      }
    },
    defineDate(timestamp) {
      var dateFormat = moment(timestamp * 1000).format('DD/MM/YYYY');
      return dateFormat;
    },
    contactSelect(contact) {
      this.$socket.client.emit('resetUnreadCount', contact.id);
      this.filterContacts.map((item) => {
        if (item.id == contact.id) {
          item.unreadCount = 0;
        }
      });
      this.selectContact(contact);
    },
  },
  computed: {
    ...mapState(['contacts', 'messages', 'selectedContact', 'findChat', 'filterApplied', 'filterContacts']),
    ...mapStateConfig(['tags', 'loggedUser', 'departments']),
    filteredContacts() {
      let array = [];
      let priorityChats = [];
      let userPriorityChats = [];

      //filtro do pesquisar
      array = this.filterContacts.filter((item) => {
        item.number = this.formattedNumber(item._serialized);
        if (item.name)
          return item.name.toLowerCase().includes(this.findChat);
        if (item.pushname)
          return item.pushname.toLowerCase().includes(this.findChat);
        if (item._serialized)
          return item.number.replace(/\s/g, '').toLowerCase().includes(this.findChat);
      })

      //nenhum contato encontrado
      if (array.length == 0 && !this.findChat) {
        let elmnt = document.getElementById("Message")
        elmnt.innerHTML = "<div v-if='findChat' class='font-style tw-mt-12 tw-w-full tw-text-center'>Nenhum contato encontrado. BBB</div>";
      }

      //isPriorityChat
      array = array.map((el) => {
        if (el.currentDepartmentId) {
          priorityChats.push({ ...el, isPriority: true });
          return { ...el, isPriority: true };
        } else {
          return el;
        }
      })

      //permisions
      if (this.loggedUser.roleId > 2) {
        this.loggedUser.departments.forEach((dep) => {
          priorityChats.forEach((chat) => {
            if (dep.id == chat.currentDepartmentId) {
              userPriorityChats.push(chat);
            }
          })
        })

        userPriorityChats = userPriorityChats.sort((a, b) => {
          return a.timestamp - b.timestamp;
        });
        userPriorityChats = userPriorityChats.reverse();
        return userPriorityChats;
      }

      //return 
      return array;
    }
  },
};
</script>

<style>
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.oneContact:hover {
  background-color: #f0f0f0;
}

.oneContact:hover .textIsPriority-class {

  color: #25d366;
}

.oneContact:hover .textIsPriority-class .text-atendimento {
  color: rgb(156, 163, 175);
}

.text-atendimento {
  transition: 0.1s;
}

.name-div {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 17px;
  color: rgb(75 85 99);
}

.name-notify {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 18px;
  color: rgb(30, 30, 30);
  font-weight: 500;
}

.date {
  font-size: 12px;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.lastMessage-notify {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px !important;
  color: rgb(70, 70, 70);
  font-weight: 500;
}

.hide-text {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 14px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 22ch;
  /*20ch*/
  white-space: nowrap;
}

.notify-color {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 10%;
  min-width: 22px;
  max-height: 22px;
  padding: 5px;
  background-color: #25d366;
  margin-right: 2px;
}

.notify-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12.5px;
  color: #ffffff;
  font-weight: 600;
}

.notify-hour {
  color: #25d366;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
}

.fontComponent {
  color: #8696a0;
  font-size: 14px;
  font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu, Cantarell, Fira Sans, sans-serif;
  font-weight: 400;
}

.textIsPriority-class {
  margin-top: 20px;
  margin-right: 160px;
  position: absolute;
  font-weight: lighter;
  width: 180px;
  font-size: 10px;
  font-family: Helvetica, sans-serif;
  color: #ffffff;
  transition: 0.5s;
}

.newChatNotification-class {
  background-color: #40aaf6;
  border-radius: 15%;
  width: min-content;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  padding: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 4px;
  font-size: 11px;
  display: flex;
  align-items: center;
}

.departmentIcon-class {
  padding: 2px;
}
</style>
